<template>
  <div class="row">
    <div
      class="col-md-12 row mt-5 flex"
      style="width: 100%; justify-content: center">
      <el-date-picker
        v-model="dates"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        @change="getReport"
        :picker-options="pickerOptions"
        :disabled="loading">
      </el-date-picker>
    </div>
    <div class="col-md-12" v-if="loading">
      <div class="row mt-5 pt-5">
        <div class="col-md-12" v-loading="loading"></div>
      </div>
    </div>
    <div class="col-md-12" v-if="!loading">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <BillingHistoryChart
            :categoriesData="messageSummaryData[0]"
            :totalRecipients="messageSummaryData[1]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BillingHistoryChart from "@/views/dashboard/main/BillingHistoryChart.vue";
  import store from "@/state/store.js";
  import appConfig from "@/app.config";

  export default {
    page: {
      title: "Message summary reports",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: {
      BillingHistoryChart,
    },
    data() {
      return {
        loading: false,
        dates: [],
        pickerOptions: {
          disabledDate: (time) => this.disabledDate(time),
          onPick: (date) => this.handlePick(date),
        },
      };
    },
    computed: {
      messageSummaryData() {
        return store.getters["admin/reporting/messageSummaryData"];
      },
    },
    methods: {
      getReport() {
        if (!this.dates || this.dates.length === 0) {
          this.dates = [];
        }
        this.loading = true;
        store
          .dispatch("admin/reporting/getMessageSummarry", {
            from: this.dates[0]?.toISOString().split("T")[0] || "",
            to: this.dates[1]?.toISOString().split("T")[0] || "",
          })
          .finally(() => (this.loading = false));
      },
      disabledDate(time) {
        if (!this.dates || this.dates.length === 0) return false;
        const startDate = new Date(this.dates[0]);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 3);
        return (
          time.getTime() > endDate.getTime() ||
          time.getTime() < startDate.getTime()
        );
      },
      handlePick(date) {
        if (date.minDate && !date.maxDate) {
          // First selection
          this.dates = [date.minDate];
          const maxDate = new Date(date.minDate);
          maxDate.setDate(maxDate.getDate() + 3);

          this.pickerOptions = {
            disabledDate: (time) => {
              return (
                time.getTime() < date.minDate.getTime() ||
                time.getTime() > maxDate.getTime()
              );
            },
            firstDayOfWeek: 1,
          };
        } else {
          // Second selection or deselection
          this.dates = date.maxDate ? [date.minDate, date.maxDate] : [];
          this.pickerOptions = {
            disabledDate: (time) => this.disabledDate(time),
            firstDayOfWeek: 1,
          };
        }
      },
    },
    mounted() {
      this.loading = true;
      this.getReport();
    },
  };
</script>

<style scoped>
  /* Your styles */
</style>
